import React, { useState } from 'react';

const Table = ({ data, columns, onCellClick, isLoading }) => {
    return (
        <>
            {isLoading ? (
                <p>Loading data...</p>
            ) : data.length > 0 ? (
                <>
                    <table>
                        <thead>
                            <tr>
                                {columns.map((col) => (
                                    <th key={col}>{col}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row, index) => (
                                <tr key={row.id || index}>
                                    {columns.map((col) => (
                                        <td
                                            key={col}
                                            title={
                                                typeof row[col] === 'object'
                                                    ? JSON.stringify(row[col])
                                                    : row[col] === null || row[col] === undefined
                                                    ? ''
                                                    : row[col].toString()
                                            }
                                            onClick={() =>
                                                onCellClick(
                                                    typeof row[col] === 'object'
                                                        ? JSON.stringify(row[col], null, 2)
                                                        : row[col]
                                                )
                                            }
                                        >
                                            {typeof row[col] === 'boolean'
                                                ? row[col].toString()
                                                : typeof row[col] === 'string'
                                                ? row[col].length > 20
                                                    ? row[col].substring(0, 20) + '...'
                                                    : row[col]
                                                : typeof row[col] === 'object'
                                                ? JSON.stringify(row[col]).substring(0, 20) + '...'
                                                : row[col]}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <p>No entries found.</p>
            )}
        </>
    );
};

export default Table;