// frontend/src/api.js
import axios from 'axios';

const isLocalhost = window.location.hostname === 'localhost';

const API_BASE_URL = isLocalhost
  ? `${window.location.protocol}//${window.location.hostname}:8000`
  : `https://api.${window.location.hostname}`;
  
export const fetchData = async (tableName, filters) => {
    try {
        // Build query parameters
        let params = {};

        // Include filters if provided
        if (filters) {
            Object.assign(params, filters);
        }

        const response = await axios.get(`${API_BASE_URL}/${tableName}/`, {
            params: params,
        });

        const data = response.data;
        const columns = data.length > 0 ? Object.keys(data[0]) : [];

        // Try to get total entries from headers or response data
        const totalEntries =
            parseInt(response.headers['x-total-count'], 10) ||
            response.data.total_entries ||
            data.length;

        return {
            data: data,
            columns: columns,
            totalEntries: totalEntries,
        };
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
};

export const getTotalEntries = async (tableName, filters) => {
    try {
        // Build query parameters
        let params = {};

        // Include filters if provided
        if (filters) {
            Object.assign(params, filters);
        }

        const response = await axios.get(`${API_BASE_URL}/${tableName}/`, {
            params: params,
        });

        // Get total entries from headers or response data
        const totalEntries =
            parseInt(response.headers['x-total-count'], 10) ||
            response.data.total_entries ||
            0;

        return totalEntries;
    } catch (error) {
        console.error('Error fetching total entries:', error);
        return 0;
    }
};