// frontend/src/components/Dropdown.js
import React from 'react';

const Dropdown = ({ onChange, selectedTable }) => {
    const tables = [
        'batches',
        'lesson-plan',
        'article',
        'qc-check',
        'qc-result',
        'question',
        'subject'
    ];

    const handleChange = (event) => {
        onChange(event.target.value);
    };

    const formatTableName = (tableName) => {
        const formattedName = tableName.replace(/-/g, ' ');
        const words = formattedName.split(' ');
        const capitalizedWords = words.map(word => {
            if (word === 'qc') {
                return 'QC';
            } else if (word === 'check') {
                return 'Check';
            } else if (word === 'result') {
                return 'Result';
            } else if (word === 'lesson') {
                return 'Lesson';
            } else if (word === 'plan') {
                return 'Plan';
            }
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
        return capitalizedWords.join(' ');
    };

    return (
        <select onChange={handleChange} value={selectedTable}>
            <option value="" disabled>Select a table</option>
            {tables.map((table) => (
                <option key={table} value={table}>
                    {formatTableName(table)}
                </option>
            ))}
        </select>
    );
};

export default Dropdown;