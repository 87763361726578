import React from 'react';
import { useState, useEffect } from 'react';
import Dropdown from './components/Dropdown';
import Filter from './components/Filter';
import Table from './components/Table';
import { fetchData, getTotalEntries } from './api';
import { getCachedData, setCachedData } from './cache';
import Popup from './components/Popup';
import { useLocation, useNavigate } from 'react-router-dom';

const App = () => {
    const [selectedTable, setSelectedTable] = useState('');
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [popupContent, setPopupContent] = useState(null);
    const [filterType, setFilterType] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [appliedFilters, setAppliedFilters] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [cachedData, setCachedData] = useState(null);
    const entriesPerPage = 10;
    const location = useLocation();
    const navigate = useNavigate();

    // Function to parse query parameters
    const parseQueryParams = () => {
        const params = new URLSearchParams(location.search);
        const table = params.get('table') || '';
        const page = parseInt(params.get('page'), 10) || 1;
        const filterType = params.get('filterType') || '';
        const filterValue = params.get('filterValue') || '';
        return { table, page, filterType, filterValue };
    };

    // Set initial state based on URL query parameters
    useEffect(() => {
        const { table, page, filterType, filterValue } = parseQueryParams();
        setSelectedTable(table);
        setCurrentPage(page);
        setFilterType(filterType);
        setFilterValue(filterValue);
        if (filterType && filterValue) {
            setAppliedFilters({ [filterType]: filterValue });
        }
    }, [location.search]);

    const updateURL = () => {
        const params = new URLSearchParams();
        if (selectedTable) params.set('table', selectedTable);
        if (currentPage) params.set('page', currentPage);
        if (filterType) params.set('filterType', filterType);
        if (filterValue) params.set('filterValue', filterValue);
        navigate({ search: params.toString() });
    };

    const handleTableChange = async (tableName) => {
        setSelectedTable(tableName);
        setCurrentPage(1);
        setData([]);
        setColumns([]);
        setAppliedFilters({});
        setFilterType('');
        setFilterValue('');
    };

    const handleCellClick = (content) => {
        setPopupContent(content);
    };

    const closePopup = () => {
        setPopupContent(null);
    };

    const applyFilter = () => {
        if (filterType && filterValue) {
            setAppliedFilters({ [filterType]: filterValue });
            setCurrentPage(1);
        } else {
            setAppliedFilters({});
        }
    };

    useEffect(() => {
        const loadData = async () => {
            if (!selectedTable) return;

            setIsLoading(true);

            // Update URL with current selections
            updateURL();

            const cacheKey = `${selectedTable}-${JSON.stringify(appliedFilters)}`;
            let cached = getCachedData(cacheKey);

            if (cached) {
                setCachedData(cached);
                setData(cached.data);
                setColumns(cached.columns);
                setTotalPages(Math.ceil(cached.totalEntries / entriesPerPage));
            } else {
                const filters = { ...appliedFilters };
                const response = await fetchData(selectedTable.replace(/_/g, '-').toLowerCase(), filters);

                if (response) {
                    setCachedData(response);
                    setData(response.data);
                    setColumns(response.columns);
                    setTotalPages(Math.ceil(response.totalEntries / entriesPerPage));
                }
            }

            setIsLoading(false);
        };

        loadData();
    }, [selectedTable, appliedFilters]);

    const handlePageChange = (event) => {
        setCurrentPage(Number(event.target.value));
    };

    return (
        <div>
            <h1>Dashboard App</h1>
            <Dropdown onChange={handleTableChange} selectedTable={selectedTable} />
            {selectedTable && selectedTable !== 'batch' && (
                <Filter
                    selectedTable={selectedTable}
                    filterType={filterType}
                    setFilterType={setFilterType}
                    filterValue={filterValue}
                    setFilterValue={setFilterValue}
                    applyFilter={applyFilter}
                />
            )}
            {selectedTable && (
                <div className="table-container">
                    <Table
                        data={cachedData ? cachedData.data.slice((currentPage - 1) * entriesPerPage, currentPage * entriesPerPage) : []}
                        columns={columns}
                        onCellClick={handleCellClick}
                        isLoading={isLoading}
                    />
                    {data.length === 0 && !selectedTable && <p>No table selected.</p>}
                    {data.length === 0 && selectedTable && <p>Loading data...</p>}
                    {totalPages > 1 && (
                        <div className="pagination">
                            <label htmlFor="page-select">Select Page: </label>
                            <select
                                id="page-select"
                                value={currentPage}
                                onChange={handlePageChange}
                            >
                                {Array.from({ length: totalPages }, (_, i) => (
                                    <option key={i + 1} value={i + 1}>
                                        {i + 1}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>
            )}
            {popupContent && (
                <Popup content={popupContent} onClose={closePopup} />
            )}
        </div>
    );
};

export default App;
