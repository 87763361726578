import React from 'react';

const Filter = ({
    selectedTable,
    filterType,
    setFilterType,
    filterValue,
    setFilterValue,
    applyFilter,
}) => {
    let filterOptions = [
        { value: 'batch_id', label: 'Batch ID' },
    ];

    if (selectedTable === 'question' || selectedTable === 'article') {
        filterOptions.push(
            { value: 'subject_id', label: 'Subject ID' },
            { value: 'lesson_plan_id', label: 'Lesson Plan ID' }
        );
    }

    const handleFilterTypeChange = (event) => {
        setFilterType(event.target.value);
    };

    const handleFilterValueChange = (event) => {
        setFilterValue(event.target.value);
    };

    return (
        <div className="filter-container">
            <label htmlFor="filter-select">Filter By: </label>
            <select
                id="filter-select"
                onChange={handleFilterTypeChange}
                value={filterType}
            >
                <option value="" disabled>Select a filter</option>
                {filterOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <input
                type="text"
                placeholder="Enter ID"
                value={filterValue}
                onChange={handleFilterValueChange}
            />
            <button onClick={applyFilter}>Apply Filter</button>
        </div>
    );
};

export default Filter;
